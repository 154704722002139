@import "fonts", "hope-ui";

:root {
   --page-uncollapsed-header-height: 61px;
   --sidenav-width: 257px;
   --closed-sidenav-width: 80px;
   --primary-shadow-color: rgb(var(--bs-body-color-rgb), .3);
   --secondary-shadow-color-1: #00000026;
   --secondary-shadow-color-2: rgb(17 38 146 / 5%);
   --secondary-trn-1: all .4s ease;
   --secondary-trn-2: all .3s ease-in-out;
   --light-purple: #6c33d3;
}

// layout 
#root {
   display: flex;
   min-height: 100vh;
}

*,
*::before,
*::after {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}


p {
   margin: 0;
}

ul:not(.list-styled) {
   list-style: none;
}

button {
   cursor: pointer;
   border: 0;
   background-color: initial;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
   color: var(--bs-primary-shade-80);
}


.btn {
   transition: color 300ms ease-in-out,
      background-color 300ms ease-in-out,
      border-color 300ms ease-in-out,
      box-shadow 300ms ease-in-out;
}

:not(img)::selection {
   background-color: var(--bs-primary);
   color: var(--bs-white);
}

img::selection {
   background-color: transparent;
}

/* make images easier to work with */
img,
picutre {
   max-width: 100%;
   display: block;
}

/* make form elements easier to work with */
input,
button,
textarea,
select {
   font: inherit;
}

// focus outline
:is(a, button, input, textarea, summary) {
   --outline-size: max(2px, 0.08em);
   --outline-style: solid;
   --outline-color: var(--bs-dark);
}

:is(a, button, summary, input[type="checkbox"]):focus {
   outline: var(--outline-size) var(--outline-style) var(--outline-color) !important;
   outline-offset: var(--outline-offset, var(--outline-size)) !important;
}

:is(a, button, summary, input[type="checkbox"]):focus-visible {
   outline: var(--outline-size) var(--outline-style) var(--outline-color) !important;
   outline-offset: var(--outline-offset, var(--outline-size)) !important;
}

:is(a, button, summary, input[type="checkbox"]):focus:not(:focus-visible) {
   outline: none !important;
}


/* remove animations for people who've turned them off */
@media (prefers-reduced-motion: reduce) {

   *,
   *::before,
   *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
   }
}

.skip-to-content {
   --outline-offset: 0;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 1031;
   background: var(--bs-white);
   transform: translateY(-100%);
   transition: transform 250ms ease-in;

   &:focus-visible {
      transform: translateY(0);
   }
}